import { Image, H4, H6, P } from '../../../../AbstractElements';
import user1 from '../../../../assets/images/user/1.jpg';
import { Col, Media, Row } from 'reactstrap';
import React, { Fragment } from 'react';

const InvoiceDescription = (props) => {
    return (
        <Fragment>
            <Row className="invo-profile">
                <Col xl="4">
                    <div className="invo-profile-left">
                        <Media className='d-flex'>
                            <div className="d-flex-left"><Image attrImage={{ className: 'media-object rounded-circle img-60', src: `${user1}`, alt: '' }} /></div>
                            <Media className='flex-grow-1'>
                                <H4 attrH4={{ className: 'd-flex-heading f-w-600' }}>{props.prop1.user_name}</H4>
                                <P>{props.prop1.email}<span className="digits">{props.prop1.mobile}</span></P>
                            </Media>
                        </Media>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};
export default InvoiceDescription;