import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAccount, uploadAccount } from "../../../CommenURL";
import { postDataWithToken } from "../../../Utils/restUtils";
import { AddTax, DiscriptionText, Name, NameError, CurrencyCode, BankNameText, BankNo, BankAddress, SubmitForm, AccountNo, OpeningBalance, BankNoError, BankNameError, AccountNoError, BankAddressError, CurrencyCodeError, OpeningBalanceError, EditAccount } from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { toast } from 'react-toastify';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const AccountEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: { }
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getAccountData();
    }, []);

    const getAccountData = async () => {
        setLoading(true);
        let data = await postDataWithToken(getAccount + `?account_id=${param1}`);
        setValue('name', data.data[0].name);
        setValue('bankName', data.data[0].bank_name);
        setValue('bankNo', data.data[0].bank_phone);
        setValue('bankAddress', data.data[0].bank_address);
        setValue('accountNo', data.data[0].account_number);
        setValue('currencyCode', data.data[0].currency_code);
        setValue('openingBalance', data.data[0].opening_balance);
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            account_id: param1,
            name: data.name,
            opening_balance: data.openingBalance,
            country_code: data.currencyCode,
            bank_name: data.bankName,
            bank_phone: data.bankNo,
            bank_address: data.bankAddress,
            account_number: data.accountNo
        };
        const addTaxResponse = await postDataWithToken(uploadAccount, postRequest);
        if (addTaxResponse.message === "success") {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/configuration/account`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={EditAccount} parent="Configuration" title="Account" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditAccount}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text"
                                    {...register('name', { required: true })}
                                />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{BankNameText}</Label>
                                <input className="form-control" id="validationCustom02" type="text"
                                    {...register('bankName', { required: true })}
                                />
                                <span className="text-danger">{errors.bankName && BankNameError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{BankNo}</Label>
                                <input className="form-control" id="validationCustom01" type="tel"
                                    {...register('bankNo', { required: true })}
                                />
                                <span className="text-danger">{errors.bankNo && BankNoError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{BankAddress}</Label>
                                <input className="form-control" id="validationCustom02" type="text"
                                    {...register('bankAddress', { required: true })}
                                />
                                <span className="text-danger">{errors.bankAddress && BankAddressError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{AccountNo}</Label>
                                <input className="form-control" id="validationCustom01" type="text"
                                    {...register('accountNo', { required: true })}
                                />
                                <span className="text-danger">{errors.accountNo && AccountNoError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{OpeningBalance}</Label>
                                <input className="form-control" id="validationCustom02" type="text"
                                    {...register('openingBalance', { required: true })}
                                />
                                <span className="text-danger">{errors.openingBalance && OpeningBalanceError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{CurrencyCode}</Label>
                                <input className="form-control" id="validationCustom01" type="text"
                                    {...register('currencyCode', { required: true })}
                                />
                                <span className="text-danger">{errors.currencyCode && CurrencyCodeError}</span>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default AccountEdit;
