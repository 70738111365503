import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getIndustryData, addTaxGroup, getIndustryUser } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { DiscriptionText, Name, NameError, Industry, LastNameError, SubmitForm, AddInvoice, Customer, TaxGroupError, Date, BillingAddress, GSTIN, Connection, InvoiceSeries } from '../../../Constant';
import Typeahead from "../../../CommonElements/TypeAhead";

const InvoiceForm = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [getUser, setGetUser] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        industryData(); // do not put await here
    }, []);

    useEffect(() => {
        setUserOption(getUser.map((item) => ({
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
        })));
    }, [getUser]);

    const industryData = async () => {
        let data = await postDataWithToken(getIndustryUser);
        console.log('bbc', data)
        if (data) {
            setGetUser(data.data);
            console.log('bbc', data.data)
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
    };

    const onSubmit = async (data) => {
        const postRequest = { title: data.name, industry_type_id: industryId, description: data.message }
        console.log('abcd', postRequest)
        // setLoading(true);
        const addTaxResponse = await postDataWithToken(addTaxGroup, postRequest);
        if (addTaxResponse) {
            // alert('You submitted the form and stuff!');
            navigate(`${process.env.PUBLIC_URL}/editor/ace-code-editor`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        // setLoading(false);
    };
    return (
        <Fragment>
            {/* {loading && (
                <SpinnerLoader/>
            )} */}

            <Breadcrumbs mainTitle={AddInvoice} parent="Configuration" title={AddInvoice} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddInvoice}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Customer}</Label>
                                <Controller
                                    name="taxGroup"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={userOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.taxGroup && TaxGroupError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Date}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="Date" {...register('lastname', { required: true })} />
                                <span className="text-danger">{errors.lastname && LastNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{BillingAddress}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="Billing Address" {...register('lastname', { required: true })} />
                                <span className="text-danger">{errors.lastname && LastNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{GSTIN}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="GST" {...register('lastname', { required: true })} />
                                <span className="text-danger">{errors.lastname && LastNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Connection}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="Connection" {...register('lastname', { required: true })} />
                                <span className="text-danger">{errors.lastname && LastNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{InvoiceSeries}</Label>
                                <input className="form-control" id="validationCustom02" type="text" placeholder="Invoice Series" {...register('lastname', { required: true })} />
                                <span className="text-danger">{errors.lastname && LastNameError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default InvoiceForm;