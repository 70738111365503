import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Btn, H3, P } from '../../../AbstractElements';
import { Add, Companies, Contact, Hometxt, MaterialColorTab, Modalcontent, nav_primary, Pillspan1, Profile, Subscription } from '../../../Constant';
import { CompanyColumns, OrderHistoryColumns, SubscriptionColumns } from '../../../Data/MockTable/TableHeaderList';
import DataTable from 'react-data-table-component';
import { getDataWithToken } from '../../../Utils/restUtils';
import { getCompanyData, getUserSubscription } from '../../../CommenURL';

const SubscriptionTableTabs = (props) => {
  const style2 = { width: 60, fontSize: 13, padding: 3, marginRight: '10px' };
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1');
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    subscriptionData(1); // do not put await here
    companyData();
  }, []);

  
  const handlePageChange = page => {
    subscriptionData(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    subscriptionData(page, newPerPage);
    setPerPage(newPerPage);
  };

  const subscriptionData = async (page, size = perPage) => {
    setLoading(true);
    let response = await getDataWithToken(getUserSubscription + `?page=${page}&page_length=${size}&search=&industry_id=&user_id=${props.params}`);
    setSubscriptionList(response.data.data.map((e, index) => {
      return {
        Si: index + 1,
        Name: e.user_name,
        PackageName: e.package_name,
        Price: e.price,
        Industry: e.industry_name,
        Start_date: e.start_date,
        End_date: e.end_date,
        Created_by: e.created_user_name,
        Status: e.status,
        // action:
        //   <div>
        //     <span>
        //       <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit</Btn>
        //     </span>
        //     <span>
        //       <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteCompanyList(e.id) } }}>Delete</Btn>
        //     </span>
        //   </div >
      }
    }))
    setTotalRows(response.data.total);
    setLoading(false);
  };

  const companyData = async () => {
    setLoading(true);
    let response = await getDataWithToken(getCompanyData + `?user_id=${props.params}`);
    setCompanyList(response.data.data.map((e, index) => {
      return {
        Si: index + 1,
        Name: <span>{e.name}</span>,
        Phone: e.phone,
        Email: e.email,
        Address: e.address,
        Status: <span className={e.status === 'active' ? 'font-success' : 'font-danger'}>{e.status}</span>,
        // action:
        //   <div>
        //     <span>
        //       <Btn attrBtn={{ style: style2, color: 'primary btn-xs', type: 'button', onClick: () => redirectToEditForm(e) }}>Edit</Btn>
        //     </span>
        //     <span>
        //       <Btn attrBtn={{ style: style2, className: 'me-2', color: 'danger btn-xs', type: 'button', onClick: () => { deleteCompanyList(e.id) } }}>Delete</Btn>
        //     </span>
        //   </div >
      }
    }))
    setTotalRows(response.data.total);
    setLoading(false);
  };

  return (
    <Col sm="12" xl="6 xl-100">
      <Card>
        <CardBody>
          <Nav className="border-tab nav-primary" tabs>
            <NavItem>
              <NavLink className={PrimarycolorLineTab === '1' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('1')}>{Subscription}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={PrimarycolorLineTab === '2' ? 'active' : ''} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('2')}>{Companies}</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={PrimarycolorLineTab}>
            <TabPane className="fade show" tabId="1">
              <div className="order-history table-responsive" style={{}}>
                <DataTable
                  columns={SubscriptionColumns}
                  data={subscriptionList}
                  progressPending={loading}
                  pagination
                  paginationServer
                  defaultSortAsc
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  // selectableRows
                  onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="order-history table-responsive" style={{}}>
                <DataTable
                  columns={CompanyColumns}
                  data={companyList}
                  progressPending={loading}
                  pagination
                  defaultSortAsc
                />
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SubscriptionTableTabs;