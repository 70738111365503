import React, { Fragment, useRef, useState } from 'react';
import { Btn } from '../../../../AbstractElements';
import { AddPayments, Cancel, Print, ThermalPrint, share } from '../../../../Constant';
import ItemDescription from './ItemDescription';
import { Col, Spinner } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router';
import Transparentspinner from '../../../Common/loader/Transparentspinner';
import { getShareApi } from '../../../../CommenURL';
import { getDataWithToken } from '../../../../Utils/restUtils';

const PrintComponent = () => {
    const location = useLocation();
    const searchParams = location.search;
    const [loading, setLoading] = useState(false)
    const params = new URLSearchParams(searchParams);
    const id = params.get("id");
    const userId = params.get("order_id");
    const navigate = useNavigate();
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const redirectToPaymentForm = () => {
        const editFormURL = `${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice/payment-add?id=${id}&user_id=${userId}`;
        navigate(editFormURL);
    };

    const shareApi = async () => {
        let responce = await getDataWithToken(getShareApi + `?order_id=${userId}`);
        if(responce.status===true){
            navigate(`/invoice?order_id=${responce.data}`);
        }
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <ItemDescription
                ref={componentRef}
                idValue={userId}
                setLoading={setLoading}
            />
            <Col sm="12" className="text-center my-3">
                <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                    {Print}
                </Btn>
                {/* <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                    {ThermalPrint}
                </Btn> */}
                <Btn attrBtn={{ color: 'secondary', className: 'me-2', onClick: () => shareApi() }}>
                    {share}
                </Btn>
                <Btn attrBtn={{ color: 'secondary', className: 'me-2', onClick: () => redirectToPaymentForm() }}>
                    {AddPayments}
                </Btn>
            </Col>
        </Fragment>
    );
};

export default PrintComponent;