import React from 'react';
import product1 from '../../assets/images/ecommerce/01.jpg';
import product2 from '../../assets/images/ecommerce/02.jpg';
import { Image, H4 } from '../../AbstractElements';
import { Input, Label } from 'reactstrap';


export const OrderHistoryData = [
  {
    id: 1,
    Product: <span><Image attrImage={{ className: "img-fluid img-30 me-2", src: `${product1}`, alt: "" }} />Women's Jacket</span>,
    Productname: "Women's Jacket",
    Size: 'M',
    Color: <span className='badge bg-lavender p-1'>{'Lavander'}</span>,
    Article_number: "G-01024224",
    Units: 1,
    Price: '$25',
    status: <span className='font-primary'>{'In Proccess'}</span>,
    payment: "Credit Card"
  },
  {
    id: 2,
    Product: <span><Image attrImage={{ className: "img-fluid img-30 me-2", src: `${product2}`, alt: "" }} />Denim Jacket</span>,
    Productname: "Denim Jacket",
    Size: 'Xl',
    Color: <span className='badge bg-blue p-1'>{"Blue"}</span>,
    Article_number: "G-2024242",
    Units: 8,
    Price: '$85',
    status: <span className='font-secondary'>{'Pending'}</span>,
    payment: "Bank Transfer"
  },
];
export const OrderHistoryColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    center: true,
    width: '50px',
  },
  {
    name: <H4>Account Name</H4>,
    selector: row => row.AccountName,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Opening Balance</H4>,
    selector: row => row.OpeningBalance,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Bank Name</H4>,
    selector: row => row.BankName,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Bank Phone no.</H4>,
    selector: row => row.Bankno,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Bank Address</H4>,
    selector: row => row.BankAddress,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Account no.</H4>,
    selector: row => row.Accountno,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Added by</H4>,
    selector: row => row.CreatedBy,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Action</H4>,
    selector: row => row.action,
    sortable: true,
    center: true,
  }
];

export const SubscriptionColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width: '60px',
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    width: '100px',
    wrap:true
  },
  {
    name: <H4>Status</H4>,
    selector: row => row.Status,
    sortable: true,
    width: '80px',
    wrap:true,
    cell: row => (
      <span
          className={`badge badge-${row.Status}`}
      >
          {row.Status}
      </span>
  ),  
},
  {
    name: <H4>Package Name</H4>,
    selector: row => row.PackageName,
    sortable: true,
    width: '100px',
    wrap:true
  },
  {
    name: <H4>Unit Price</H4>,
    selector: row => "₹" + row.Price,
    sortable: true,
    width: '100px',
    wrap:true
  },
  {
    name: <H4>Industry</H4>,
    selector: row => row.Industry,
    sortable: true,
    width: '100px',
    wrap:true
  },
  {
    name: <H4>Start date</H4>,
    selector: row => row.Start_date,
    sortable: true,
    width: '100px',
    wrap:true
  },
  {
    name: <H4>End date</H4>,
    selector: row => row.End_date,
    sortable: true,
    width: '100px',
    wrap:true
  },
];

export const CompanyColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width: '70px',
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    width: '100px',
    wrap:true
},
{
  name: <H4>Status</H4>,
  // selector: row => row.Status,
  sortable: true,
  width: '100px',
  wrap:true,
  cell: row => (
    <span
        className={`badge badge-${row.Status}`}
    >
        {row.Status}
    </span>
),  

},
{
    name: <H4>Phone</H4>,
    selector: row =>"+" + row.Phone,
    sortable: true,
    width: '13  0px',
    wrap:true
},
  {
    name: <H4>Email</H4>,
    selector: row => row.Email,
    sortable: true,
    width: '140px',
    wrap:true
},
  {
    name: <H4>Address</H4>,
    selector: row => row.Address,
    sortable: true,
    width: '150px',
    wrap:true
},
  // {
  //   name: <H4>Action</H4>,
  //   selector: row => row.action,
  //   sortable: true,
  //   center: true,
  // }
];

export const RoleColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    center: true,
    width: '50px',
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Action</H4>,
    selector: row => row.action,
    sortable: true,
    center: true,
  }
];

export const PermissionColumns = [
  {
    name: 'Module',
    // selector: 'name',
    selector: row => row.name,
    sortable: true
  },
  {
    name: 'Checkboxes',
    cell: (row) => (
      row.checkboxes.map((checkbox, index) => (
        <React.Fragment key={index}>
          <div className="form-check form-check-inline checkbox checkbox-dark mb-0">
            <Input id={`checkbox-${row.id}-${index}`} type="checkbox" />
            <Label for={`checkbox-${row.id}-${index}`}>{checkbox.displayName}</Label>
          </div>
        </React.Fragment>
      ))
    )
  }
];

export const AddonsColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    center: true,
    width: '50px',
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Industry</H4>,
    selector: row => row.Industry,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Description</H4>,
    selector: row => row.Description,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Price</H4>,
    selector: row => row.Price,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Mrp</H4>,
    selector: row => row.Mrp,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Action</H4>,
    selector: row => row.action,
    sortable: true,
    center: true,
  }
];


export const AddressColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    center: true,
    width: '50px',
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Company_name</H4>,
    selector: row => row.CompanyName,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Address</H4>,
    selector: row => row.Address,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Action</H4>,
    selector: row => row.action,
    sortable: true,
    center: true,
  }
];

export const PaymentColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    center: true,
    width: '50px',
  },
  {
    name: <H4>Invoice No</H4>,
    selector: row => row.InvoiceNo,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Industry Name</H4>,
    selector: row => row.IndustryName,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Paid At</H4>,
    selector: row => row.PaidAt,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Payment Method</H4>,
    selector: row => row.PaymentMethod,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Amount</H4>,
    selector: row => row.Amount,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Status</H4>,
    selector: row => row.Status,
    sortable: true,
    center: true,
  },
  // {
  //   name: <H4>Action</H4>,
  //   selector: row => row.action,
  //   sortable: true,
  //   center: true,
  // }
];