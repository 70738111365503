import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getIndustryData, addTaxGroup } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { AddTax, DiscriptionText, Name, NameError, Industry, LastNameError, SubmitForm, IndustryError, AddTaxGroup, Code ,CodeError} from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Typeahead from "../../../CommonElements/TypeAhead";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const TaxGroupForm = () => {
    const schema = Yup
        .object()
        .shape({
            industry: Yup.mixed().required(),
            name: Yup.string().required(),
            code: Yup.string().required(),
        })
        .required();
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [industry, setIndustry] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        industryData(); // do not put await here
    }, []);

    useEffect(() => {
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [industry]);

    const industryData = async () => {
        let data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            title: data.name,
            code: data.code,
            industry_type_id: data.industry.value,
            description: data.message
        }
        const addTaxResponse = await postDataWithToken(addTaxGroup, postRequest);
        if (addTaxResponse.message === 'success') {
            navigate(`${process.env.PUBLIC_URL}/configuration/tax-list`);
        } else {
            setLoading(false);
            // errors.showMessages();
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={AddTaxGroup} parent="Configuration" title="Tax Group" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddTaxGroup}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" placeholder="Username" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name ? NameError : ''}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Code} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" placeholder="" {...register('code', { required: true })} />
                                <span className="text-danger">{errors.code ? CodeError : ''}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Industry} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                {/* <span className="text-danger">{industryOptionSelected && 'Please select an industry.'}</span> */}
                                <span className="text-danger">{errors.industry ? IndustryError : ''}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default TaxGroupForm;