import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const login = (localStorage.getItem('superAdmin')) ? (localStorage.getItem('superAdmin')) : false
    return (
        login !== false ?
            <Outlet />
            :
            <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />
    );
};
export default PrivateRoute;

