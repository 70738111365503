import React, { Fragment } from 'react';
import UserProfileImg from '../../../assets/images/user-profile/bg-profile.jpg';
import { Col, Card, Row } from 'reactstrap';
import UserSeven from '../../../assets/images/user/7.jpg';
import { H4, H6, Image, LI, UL } from '../../../AbstractElements';
import { Designer, EmayWalter, Follower, Following, Likes, TotalFollowers, TotalFollowing, TotalLikes } from '../../../Constant';
import StaticWidgets from '../../Widgets/General/StaticWidgets';

const UserProfile = (props) => {
    const personalDetails = props.details;
    const propsParam = props.param;
    return (
        <Fragment>
            <Col sm="12">
                <Card className="profile-header bg-size">
                    <Row>
                        <Col sm="9" lg="5">
                            <div className="profile-img-wrrap">
                                <Image attrImage={{ className: 'img-fluid bg-img-cover', src: `${require('../../../assets/images/user-profile/bg-profile.jpg')}`, alt: '' }} />
                            </div>
                            <div className="userpro-box">
                                <div className="img-wrraper">
                                    <div className="avatar">
                                        <Image attrImage={{ className: 'step1', alt: '', src: `${UserSeven}`, dataintro: 'This is Profile image' }} />
                                    </div>
                                </div>
                                <div className="user-designation">
                                    <div className="title"><a target="_blank" href="#javascript">
                                        <H4>{personalDetails.first_name} {personalDetails.last_name}</H4>
                                        <H6>{personalDetails.industry_name}</H6></a></div>
                                    <div className="follow">
                                        <UL attrUL={{ className: 'follow-list flex-row simple-list' }}>
                                            <LI>
                                                <div className="follow-num counter">{TotalFollowers}</div><span>{Follower}</span>
                                            </LI>
                                            <LI>
                                                <div className="follow-num counter">{TotalFollowing}</div><span>{Following}</span>
                                            </LI>
                                            <LI>
                                                <div className="follow-num counter">{TotalLikes}</div><span>{Likes}</span>
                                            </LI>
                                        </UL>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs='12' sm="6" lg="7" className='personalBusinessDetails'>
                            <Row className='profileCards'>
                                <StaticWidgets param1={propsParam} />
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Fragment>
    );
};

export default UserProfile;