import React, { Fragment } from "react";
import { footerFixed, PageLayout } from "../../../Constant";
import {} from '../../../Constant';
import RoleList from "./RoleList";

const RoleContainer = () => {
    return (
        <Fragment>
            <RoleList mainTitle={footerFixed} title={footerFixed} parent={PageLayout} />  
        </Fragment>
    )
}
export default RoleContainer;