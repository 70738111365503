// dashbaord
import Default from '../Components/Dashboard/Default';
import Ecommerce from '../Components/Dashboard/Ecommerce';

//Page layout
import AccountAdd from '../Components/Configuration/Account/AccountAdd';
import Account from '../Components/Configuration/Account/Account';

//forms
import IndustryAdd from '../Components/Forms/FormControl/IndustryAdd';

import UsersProfileContain from '../Components/App/Users/UsersProfile';
import Invoice from '../Components/App/Ecommerce/Invoice';

//sample page
import AccountEdit from '../Components/Configuration/Account/AccountEdit';
import RoleContainer from '../Components/Masterrecords/Role/Role';
import RoleAdd from '../Components/Masterrecords/Role/RoleAdd';
import AddonsContainer from '../Components/Masterrecords/Addon/Addons';
import AddonsAdd from '../Components/Masterrecords/Addon/AddAddons';
import AddonsEdit from '../Components/Masterrecords/Addon/AddAddonsEdit';
import UserAddress from '../Components/Masterrecords/User/UserAddress';
import UserAddressAdd from '../Components/Masterrecords/User/UserAddressAdd';
import PaymentAdd from '../Components/App/Ecommerce/Invoice/AddPayments';
import CompanyList from '../Components/Companies/companies/CompanyList';
import CompanyEdit from '../Components/Companies/companies/CompanyEdit';
import CompanyAdd from '../Components/Companies/companies/CompanyAdd';
import Temperoraylist from '../Components/Companies/temporary/TempList';
import InvoiceAdd from '../Components/Billing/invoice/InvoiceAdd';
import Invoicelist from '../Components/Billing/invoice/Invoice';
import Subscription from '../Components/Billing/subscription/Subscription';
import SubscriptionAdd from '../Components/Billing/subscription/SubscriptionAdd';
import IndustryEdit from '../Components/Configuration/Industries/IndustryEdit';
import Industryy from '../Components/Configuration/Industries/Industryy';
import TaxGroupAdd from '../Components/Configuration/Tax/TaxGroupAdd';
import TaxGroupEdit from '../Components/Configuration/Tax/TaxGroupEdit';
import TaxAddForm from '../Components/Configuration/Tax/TaxAdd';
import TaxEdit from '../Components/Configuration/Tax/TaxEdit';
import Tax from '../Components/Configuration/Tax/Tax';
import Packages from '../Components/Configuration/Packages/Packages';
import PackagesAdd from '../Components/Configuration/Packages/PackagesAdd';
import PackagesEdit from '../Components/Configuration/Packages/PackagesEdit';
import CouponList from '../Components/Configuration/Coupon/CouponTable';
import CouponForm from '../Components/Configuration/Coupon/CouponForm';
import CouponEdit from '../Components/Configuration/Coupon/CouponEdit';
import PaymentForm from '../Components/Configuration/PaymentMethod/PaymentForm';
import PaymentEdit from '../Components/Configuration/PaymentMethod/PaymentEdit';
import RoleEdit from '../Components/Masterrecords/Role/RoleEdit';
import SubscriptionEdit from '../Components/Billing/subscription/SubscriptionEdit';
import PaymentMethodList from '../Components/Configuration/PaymentMethod/PaymentList';
import Paymentlist from '../Components/Billing/Payment/Payment';
import ThermalPrint from '../Components/Billing/ThermalPrint/ThermalPrint-demo';
import TrialTable from '../Components/Companies/trialExpired/TrialTable';
import PrintComponent from '../Components/App/Ecommerce/Invoice/Print';

export const routes = [
        //dashboard
        { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: <Default /> },
        { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/`, Component: <Ecommerce /> },
        
        //Companies
        { path: `${process.env.PUBLIC_URL}/subscriptions/active-sub`, Component: <CompanyList /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/company-edit`, Component: <CompanyEdit /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/company-add`, Component: <CompanyAdd /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/company-list/userProfile`, Component: <UsersProfileContain /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/company-list/userProfile/invoice`, Component: <Invoice /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/company-list/userProfile/invoice/payment-add`, Component: <PaymentAdd /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/temp-sub`, Component: <Temperoraylist /> },
        { path: `${process.env.PUBLIC_URL}/subscriptions/trial-expired`, Component: < TrialTable/> },

        //Billing
        { path: `${process.env.PUBLIC_URL}/billing/invoice-list`, Component: <Invoicelist /> },
        { path: `${process.env.PUBLIC_URL}/billing/invoice-add`, Component: <InvoiceAdd /> },
        { path: `${process.env.PUBLIC_URL}/billing/payment-list`, Component: <Paymentlist /> },
        { path: `${process.env.PUBLIC_URL}/billing/subscription`, Component: <Subscription /> },
        { path: `${process.env.PUBLIC_URL}/billing/subscription-add`, Component: <SubscriptionAdd /> },
        { path: `${process.env.PUBLIC_URL}/billing/subscription-edit`, Component: <SubscriptionEdit /> },
        { path: `${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice`, Component: <PrintComponent /> },
        
        //Configuration
        { path: `${process.env.PUBLIC_URL}/configuration/industry-list`, Component: <Industryy /> },
        { path: `${process.env.PUBLIC_URL}/configuration/industry-add`, Component: <IndustryAdd /> },
        { path: `${process.env.PUBLIC_URL}/configuration/industry-edit`, Component: <IndustryEdit /> },
        
        { path: `${process.env.PUBLIC_URL}/configuration/tax-list`, Component: <Tax /> },
        { path: `${process.env.PUBLIC_URL}/configuration/tax-group-add`, Component: <TaxGroupAdd /> },
        { path: `${process.env.PUBLIC_URL}/configuration/tax-group-edit`, Component: <TaxGroupEdit /> },
        { path: `${process.env.PUBLIC_URL}/configuration/tax-add`, Component: <TaxAddForm /> },
        { path: `${process.env.PUBLIC_URL}/configuration/tax-edit`, Component: <TaxEdit /> },

        { path: `${process.env.PUBLIC_URL}/configuration/account`, Component: <Account /> },
        { path: `${process.env.PUBLIC_URL}/configuration/account-add`, Component: <AccountAdd /> },
        { path: `${process.env.PUBLIC_URL}/configuration/account-edit`, Component: <AccountEdit /> },

        { path: `${process.env.PUBLIC_URL}/configuration/packages`, Component: <Packages /> },
        { path: `${process.env.PUBLIC_URL}/configuration/packages-add`, Component: <PackagesAdd /> },
        { path: `${process.env.PUBLIC_URL}/configuration/packages-edit`, Component: <PackagesEdit /> },

        { path: `${process.env.PUBLIC_URL}/configuration/coupon`, Component: <CouponList/> },
        { path: `${process.env.PUBLIC_URL}/configuration/coupon-add`, Component: <CouponForm/> },
        { path: `${process.env.PUBLIC_URL}/configuration/coupon-edit`, Component: <CouponEdit/> },
        
        { path: `${process.env.PUBLIC_URL}/configuration/payment-method`, Component: <PaymentMethodList/> },
        { path: `${process.env.PUBLIC_URL}/configuration/payment-method-add`, Component: <PaymentForm/> },
        { path: `${process.env.PUBLIC_URL}/configuration/payment-method-edit`, Component: <PaymentEdit/> },
        

        //master records
        { path: `${process.env.PUBLIC_URL}/master-records/role`, Component: <RoleContainer /> },
        { path: `${process.env.PUBLIC_URL}/master-records/role-add`, Component: <RoleAdd /> },
        { path: `${process.env.PUBLIC_URL}/master-records/role-edit`, Component: <RoleEdit /> },

        { path: `${process.env.PUBLIC_URL}/master-records/addons`, Component: <AddonsContainer /> },
        { path: `${process.env.PUBLIC_URL}/master-records/addons-add`, Component: <AddonsAdd /> },
        { path: `${process.env.PUBLIC_URL}/master-records/addons-edit`, Component: <AddonsEdit /> },

        { path: `${process.env.PUBLIC_URL}/master-records/user-address`, Component: <UserAddress /> },
        { path: `${process.env.PUBLIC_URL}/master-records/user-address-add`, Component: <UserAddressAdd /> },
        
        //thermal-print
        { path: `${process.env.PUBLIC_URL}/companies/company-list/invoice/thermal-print`, Component: <ThermalPrint /> },
];
