import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Breadcrumbs,Btn, H3 } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { NameError, SubmitForm, Industry, PriceError, MrpError, IndustryError, DaysError, AddCompany, FirstName, LastName, Email, Country, Phone, Password, ConfirmPassword, FirstNameError, LastNameError, EmailError, CountryError, PasswordError, PhoneError, ConfirmPasswordError } from '../../../Constant';
import { getIndustryData, addPackages, getTaxGroup } from "../../../CommenURL";
import { postDataWithToken,getDataWithToken } from "../../../Utils/restUtils";
import Typeahead from "../../../CommonElements/TypeAhead";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const CompanyForm = () => {
    const { register, handleSubmit, control, formState: { errors }, watch, getValues, trigger, setValue } = useForm();
    const [industry, setIndustry] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        industryData();
    }, []);

    useEffect(() => {
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [industry]);

    const industryData = async () => {
        const data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        console.log(errors);
        console.log('field',data);
        const isIndustryValid = await trigger('industry');
        console.log('field',isIndustryValid);
        const postRequest = { 
            name: data.name, price: data.price, mrp: data.mrp, 
            tax_group_id: data.taxGroup.value, industry_type_id: data.industry.value, 
            features: data.features, description: data.message, setup_cost: data.setupCost.value, 
            expity_date: data.expiryDate, days: data.days, hsn_no: data.hsn 
        }
        const addPackageResponse = await postDataWithToken(addPackages, postRequest);
        console.log('data',addPackageResponse);
        if (addPackageResponse.message === 'success') {
            navigate(`${process.env.PUBLIC_URL}/companies/company-list`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Add Company' parent="Companies" title="Company" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddCompany}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md="6">
                                <Label className="form-label" >{Industry}</Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    validate={value => value ? undefined : IndustryError}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                setValue('industry', value);
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="first_name">{FirstName}</Label>
                                <input className="form-control" id="first_name" type="text" {...register('firstName', { required: true })} />
                                <span className="text-danger">{errors.firstName && FirstNameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="last_name">{LastName}</Label>
                                <input className="form-control" id="last_name" type="text" {...register('lastName', { required: true })} />
                                <span className="text-danger">{errors.lastName && LastNameError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="email">{Email}</Label>
                                <input className="form-control" id="email" type="text" {...register('email', { required: true })} />
                                <span className="text-danger">{errors.email && EmailError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="country">{Country}</Label>
                                <input className="form-control" id="country" type="text" {...register('country', { required: true })} />
                                <span className="text-danger">{errors.country && CountryError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="phone">{Phone}</Label>
                                <input className="form-control" id="phone" type="text" {...register('phone', { required: true })} />
                                <span className="text-danger">{errors.phone && PhoneError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="password">{Password}</Label>
                                <input className="form-control" id="password" type="password" {...register('password', { required: true })} />
                                <span className="text-danger">{errors.password && PasswordError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="confirm_password">{ConfirmPassword}</Label>
                                <input className="form-control" id="confirm_password" type="password"
                                    {...register("password_repeat", { required: true })}
                                />
                                {watch("password_repeat") !== watch("password") &&
                                    getValues("password_repeat") ? (
                                        <span className="text-danger">password not match</span>
                                ) : null}
                                <span className="text-danger">{errors.password_repeat && ConfirmPasswordError}</span>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default CompanyForm;