import React from 'react';
import { H4 } from '../../AbstractElements';


export const TempDataColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width: '70px'
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    width: '170px',
    wrap: true,
  },
  {
    name: <H4>Email</H4>,
    selector: row => row.Email,
    sortable: true,
    width: '230px',
    wrap: true,
  },
  {
    name: <H4>Phone No.</H4>,
    selector: row => "+" + row.Mobile_Number,
    sortable: true,
    width: '170px',
    wrap: true,
  },
  {
    name: <H4>Industry</H4>,
    selector: row => row.Industry,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>State</H4>,
    selector: row => row.State,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Country</H4>,
    selector: row => row.Country,
    sortable: true,
    width: '150px',
    wrap: true,
  },
];

export const PaymentColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width: '70px'
  },
  {
    name: <H4>Name</H4>,
    selector: row => row.Name,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Invoice No</H4>,
    // selector: row => <Link to={`${process.env.PUBLIC_URL}/companies/company-list/userProfile/invoice?id=${row.User_id}&order_id=${row.Id}`}>{row.Invoice_no}</Link>,
    selector: row => row.Invoice_no,
    sortable: true,
    width: '120px',
    wrap: true,
  },
  {
    name: <H4>Txn Id</H4>,
    selector: row => row.Txn_id,
    sortable: true,
    width: '170px',
    wrap: true,
  },
  {
    name: <H4>Industry</H4>,
    selector: row => row.Industry,
    sortable: true,
    width: '120px',
    wrap: true,
  },
  {
    name: <H4>Payment Method</H4>,
    selector: row => row.Payment_method,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Date</H4>,
    selector: row => row.Date,
    sortable: true,
    width: '150px',
    wrap: true,
  },
  {
    name: <H4>Amount</H4>,
    selector: row => row.Amount,
    sortable: true,
    width: '100px',
    wrap: true,
  },
  {
    name: <H4>Status</H4>,
    selector: row => row.Status,
    cell: row => (
      <span
          className={`badge badge-${row.Status}`}
      >
          {row.Status}
      </span>
  ),  

    sortable: true,
    width:'80px',
    wrap:true,
  },
];