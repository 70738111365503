import React from 'react'
import { Footerdark, PageLayout } from '../../../Constant'
import IndustryTable from './IndustryTable'

const Industryy = () => {
    return (
        <>
            <IndustryTable mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />
        </>
    )
}

export default Industryy