import React, { Fragment, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { postAddAccount } from "../../../CommenURL";
import { postDataWithToken } from "../../../Utils/restUtils";
import { AddTax, DiscriptionText,  Name, NameError, CurrencyCode,BankNameText,BankNo,BankAddress, SubmitForm, AccountNo, OpeningBalance, BankNoError, BankNameError, AccountNoError, BankAddressError, CurrencyCodeError, OpeningBalanceError, AddAccount } from '../../../Constant';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const AccountAddContainer = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [loading,setLoading]=useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {

        setLoading(true);
        const postRequest={name:data.name, opening_balance:data.openingBalance, country_code:data.currencyCode, bank_name:data.bankName, bank_phone:data.bankNo, bank_address:data.bankAddress, account_number:data.accountNo }
        const addTaxResponse = await postDataWithToken(postAddAccount, postRequest);
        if (addTaxResponse) {
            navigate(`${process.env.PUBLIC_URL}/configuration/account`);
        } else {
            // errors.showMessages();
            setLoading(false);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner/>
            )}
            
            <Breadcrumbs mainTitle='Add Account' parent="Configuration" title="Account" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddAccount}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                            <Label className="form-label" for="validationCustom02">{BankNameText} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('bankName',{ required: true })} />
                                <span className="text-danger">{errors.bankName && BankNameError}</span>   
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{BankNo} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="tel" {...register('bankNo', { required: true })} />
                                <span className="text-danger">{errors.bankNo && BankNoError}</span>
                            </Col>
                            <Col md="6">
                            <Label className="form-label" for="validationCustom02">{BankAddress} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('bankAddress',{ required: true })} />
                                <span className="text-danger">{errors.bankAddress && BankAddressError}</span>   
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{AccountNo} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('accountNo', { required: true })} />
                                <span className="text-danger">{errors.accountNo && AccountNoError}</span>
                            </Col>
                            <Col md="6">
                            <Label className="form-label" for="validationCustom02">{OpeningBalance} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="number" {...register('openingBalance',{ required: true })} />
                                <span className="text-danger">{errors.openingBalance && OpeningBalanceError}</span>   
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{CurrencyCode} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('currencyCode', { required: true })} />
                                <span className="text-danger">{errors.currencyCode && CurrencyCodeError}</span>
                            </Col>
                        </Row>
                        {/* <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row> */}
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AccountAddContainer;