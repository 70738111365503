import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { editCoupon, updateCoupon } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { EditCoupon, CouponName, Code, Type, SubmitForm, Discount, StartDate, EndDate, UsersPerCoupon, UsersPerCustomer } from '../../../Constant';
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    code: yup.string().required().label('Code'),
    type: yup.mixed().required().label('Type'),
    discount: yup.string().required().label('Discount'),
    max_uses: yup.string().required().label('Use Per Coupon'),
    use_per_customer: yup.string().required().label('Use Per Customer'),
  });

const CouponEdit = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const typeOptions = [
        { value: 'cash', label: 'Cash' },
        { value: 'percentage', label: 'Percentage' },
    ];

    useEffect(() => {
        getCouponData();
    }, []);

    const handleChangeStartDate = date => {
        setStartDate(date);
        setValue('start_date', date); 

    };

    const handleChangeEnddate = date => {
        setEndDate(date);
        setValue('end_date', date);
    };

    const getCouponData = async () => {
        setLoading(true);
        let data = await getDataWithToken(editCoupon + `?coupon_id=${param1}`);
        console.log("coupon data",data)
        if (data && data.status && data.data) {
            setValue('name', data.data.name);
            setValue('code', data.data.code);
            setValue('type', { label: data.data.type_id, value: data.data.type_id });
            setValue('discount', data.data.discount);
            setValue('max_uses', data.data.max_uses)
            setValue('use_per_customer', data.data.use_per_customer);
    
            const start_dates = data.data.start_date;
            if (start_dates) {
              setStartDate(new Date(start_dates));
            }
    
            const end_dates = data.data.end_date;
            if (end_dates) {
              setEndDate(new Date(end_dates));
            }
    
            const type = typeOptions.find((e) => e.value === data.data.type);
            setValue("type", { value: data.data.type, label: data.data.type });
        } 
        setLoading(false);
    }
    

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            coupon_id: param1,
            name: data.name,
            code: data.code,
            discount: data.discount,
            type: data.type.value,
            start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'), 
            end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            max_uses: data.max_uses,
            use_per_customer: data.use_per_customer,
        }
        const editPackageResponse = await postDataWithToken(updateCoupon, postRequest);
        if (editPackageResponse.status === true) {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/configuration/coupon`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Coupons' parent="Configuration" title="Coupons" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditCoupon}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{CouponName} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && 'Name is required'}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="code">{Code} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="code" type="text"  {...register('code', { required: true })} />
                                <span className="text-danger">{errors.code && 'Code is required'}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={typeOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.type && 'Type is required'}</span>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" for="discount">{Discount} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="discount" type="number" min="0" {...register('discount', { required: true })} />
                                <span className="text-danger">{errors.discount && 'Discount is required'}</span>
                            </Col>

                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="startDate">{StartDate}  <span className="requireStar">*</span></Label>
                                    <Col xl='12' md="12" sm='12'>
                                        <DatePicker
                                            id="startDate"
                                            style={{ padding: '12px 100px' }}
                                            className="form-control "
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="dd/mm/yyyy"
                                            selected={startDate}
                                            onChange={handleChangeStartDate}
                                            showMonthDropdown
                                            showYearDropdown

                                            dropdownMode="select"

                                            required
                                        />

                                    </Col>
                                    {/* <p className="text-danger">{errors.invoice_date?.message}</p> */}
                                </FormGroup>
                            </Col>

                          
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="endDate">{EndDate} <span className="requireStar">*</span></Label>
                                    <Col xl='12' md="12" sm='12'>
                                        <DatePicker
                                            id="endDate"
                                            style={{ padding: '12px 100px' }}
                                            className="form-control "
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="dd/mm/yyyy"
                                            selected={endDate}
                                            onChange={handleChangeEnddate}
                                            showMonthDropdown
                                            showYearDropdown

                                            dropdownMode="select"

                                            required
                                        />

                                    </Col>
                                    {/* <p className="text-danger">{errors.invoice_date?.message}</p> */}
                                </FormGroup>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" for="max_uses">{UsersPerCoupon}<span className="requireStar">*</span></Label>
                                <input className="form-control" id="max_uses" type="number" min="0" {...register('max_uses', { required: true })} />
                                <p className="text-danger">{errors.max_uses?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="use_per_customer">{UsersPerCustomer} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="use_per_customer" type="number" min="0" {...register('use_per_customer', { required: true })} />
                                <p className="text-danger">{errors.use_per_customer?.message}</p>
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default CouponEdit;
