import React, { Fragment } from "react";
import { footerFixed, PageLayout } from "../../../Constant";
import {} from '../../../Constant';
import AddonsList from "./AddonsList";

const AddonsContainer = () => {
    return (
        <Fragment>
            <AddonsList mainTitle={footerFixed} title={footerFixed} parent={PageLayout} />  
        </Fragment>
    )
}
export default AddonsContainer;