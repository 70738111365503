import React, { Fragment } from "react";
import { footerFixed, PageLayout } from "../../../Constant";
// import {} from '../../../Constant';
import AccountList from "./AccountList";

const AccountContainer = () => {
    return (
        <Fragment>
            <AccountList mainTitle={footerFixed} title={footerFixed} parent={PageLayout} />  
        </Fragment>
    )
}
export default AccountContainer;