import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import PackageListTable from "./PackagesTable";

const Packages = () => {
    return (
        <Fragment>
            <PackageListTable mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default Packages;