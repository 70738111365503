import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, InputGroup, Input } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getIndustryData, addPackages, getTaxGroup } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { DiscriptionText, Name, NameError, SubmitForm, AddPackages, Features, Price, MRP, TaxGroup, Industry, SetupCost, ExpiryDate, Days, FeaturesError, PriceError, MrpError, TaxGroupError, IndustryError, SetupCostError, ExpiryDateError, DaysError, HSN, UsingLocales, MinConnection, MaxConnection, PriceCalculation, TaxClass, SMSActive } from '../../../Constant';
import Typeahead from "../../../CommonElements/TypeAhead";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Transparentspinner from "../../Common/loader/Transparentspinner";

const PackagesForm = () => {
    const schema = Yup
        .object()
        .shape({
            // industry: Yup.mixed().required(),
            // taxGroup: Yup.mixed().required(),
            // setupCost: Yup.mixed().required(),
            // name: Yup.string().required(),
            // features: Yup.string().required(),
            // price: Yup.number().required(),
            // mrp: Yup.number().required(),
            // expiryDate: Yup.date().required(),
            // days: Yup.string().required(),
            // hsn: Yup.number().required(),
        })
        .required();
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [industry, setIndustry] = useState([]);
    const [tax, setTax] = useState([]);
    const [taxOption, setTaxOption] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const priceCalOptions = [
        { value: 'fixed', label: 'Fixed Amount' },
        { value: 'per_unit', label: 'Per Unit' },
    ];

    useEffect(() => {
        industryData();
        taxData();
    }, []);

    useEffect(() => {
        setTaxOption(tax.map((item) => ({
            value: item.id,
            label: item.title,
        })));
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [tax, industry]);

    const industryData = async () => {
        const data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const taxData = async () => {
        const getTaxdata = await getDataWithToken(getTaxGroup);
        setTax(getTaxdata.data);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { name: data.name,
             price: data.price, 
             mrp: data.mrp, 
             tax_group_id: data.taxClass.value, 
             industry_type_id: data.industry.value, features: data.message, setup_cost: data.setupCost, duration: data.expDate, min_connection: data.minCon, hsn_no: data.hsn, max_connection: data.maxCon, price_calculation: data.priceCal.value, sms: data.sms }
        const addPackageResponse = await postDataWithToken(addPackages, postRequest);
        if (addPackageResponse.message === 'success') {
            navigate(`${process.env.PUBLIC_URL}/configuration/packages`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Add Packages' parent="Configuration" title="Packages" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddPackages}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Industry}</Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry ? IndustryError : ''}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name ? NameError : ''}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{HSN}</Label>
                                <input className="form-control" id="validationCustom01" type="number" min="0" {...register('hsn', { required: true })} />
                                <span className="text-danger">{errors.hsn?.message}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{MinConnection}</Label>
                                <input className="form-control" id="validationCustom01" type="number" min="0" {...register('minCon', { required: true })} />
                                <span className="text-danger">{errors.price ? PriceError : ''}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{MaxConnection}</Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('maxCon', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{PriceCalculation}</Label>
                                <Controller
                                    name="priceCal"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={priceCalOptions}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.setupCost ? SetupCostError : ''}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Price}</Label>
                                <input className="form-control" id="validationCustom01" type="number" min="0" {...register('price', { required: true })} />
                                <span className="text-danger">{errors.price ? PriceError : ''}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{MRP}</Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('mrp', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{SetupCost}</Label>
                                <input className="form-control" id="validationCustom02" type="number" min="0" {...register('setupCost', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{TaxClass}</Label>
                                <Controller
                                    name="taxClass"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                {/* <span className="text-danger">{errors.taxGroup ? TaxGroupError : ''}</span> */}
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="validationCustom02">{ExpiryDate}</Label>
                                    <input className="form-control" id="validationCustom02" type="number" min="0" {...register('expDate', { required: true })} />
                                    <span className="text-danger">{errors.mrp && MrpError}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col md='6'>
                                <div className="form-check checkbox checkbox-primary mb-0">
                                    <input id="checkbox-primary-1" type="checkbox" {...register('sms', { required: true })} defaultChecked={false} />
                                    {/* <Input id="checkbox-primary-1" type="checkbox" {...register('sms', { required: true })}/> */}
                                    <Label for="checkbox-primary-1">{SMSActive}</Label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Features}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default PackagesForm;