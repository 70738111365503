import React from 'react';
import { H4 } from '../../AbstractElements';


export const InvoiceDataColumns = [
  {
    name: <H4>Sl</H4>,
    selector: row => row.Si,
    sortable: true,
    width:'60px'
  },
  {
    name: <H4>Invoice No</H4>,
    selector: row => row.Invoice_no,
    sortable: true,
    width:'70px'
  },
  {
    name: <H4>Invoiced Date</H4>,
    selector: row => row.Invoiced_at,
    sortable: true,
    width:'120px'
  },
  {
    name: <H4>Billing Date</H4>,
    selector: row => row.Billing_at,
    sortable: true,
  },
  {
    name: <H4>Subscription</H4>,
    selector: row => row.Subscription,
    sortable: true,
  },
  {
    name: <H4>Amount Due</H4>,
    selector: row => row.Amount_due,
    sortable: true,
  },
  {
    name: <H4>Tax</H4>,
    selector: row => row.Tax,
    sortable: true,
    width:'90px'
  },
  {
    name: <H4>Total</H4>,
    selector: row => row.Total,
    sortable: true,
    width:'90px'
  },
  {
    name: <H4>Unit Price</H4>,
    selector: row => row.Unit_price,
    sortable: true,
    width:'90px'
  },
  {
    name: <H4>Status</H4>,
    selector: row => row.Status,
    sortable: true,
    cell: row => (
      <span
          className={`badge badge-${row.Status}`}
      >
          {row.Status}
      </span>
  ),  
    },
  {
    name: <H4>Action</H4>,
    selector: row => row.action,
    sortable: true,
  }
];