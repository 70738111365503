import React, { Fragment } from "react";
import { footerFixed, PageLayout } from "../../../Constant";
import UserAddressList from "./UserAddressList";

const UserAddress = () => {
    return (
        <Fragment>
            <UserAddressList mainTitle={footerFixed} title={footerFixed} parent={PageLayout} />  
        </Fragment>
    )
}
export default UserAddress;