import React, { Fragment } from "react";
import { AddTax, PageLayout } from "../../../Constant";
import TaxGroupForm from "./TaxGroupForm";

const TaxGroupAdd = () => {
    return (
        <Fragment>
            <TaxGroupForm mainTitle={AddTax} title={AddTax} parent='Configuration' />  
        </Fragment>
    )
}
export default TaxGroupAdd;