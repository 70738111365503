import React, { Fragment, useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from '../../../Constant';
import { Link, useNavigate } from 'react-router-dom';
import { adminLogin } from '../../../CommenURL';
import { postDataWithoutResData } from "../../../Utils/restUtils";
import * as authServices from "../../../Utils/authServices";
import { useDispatch } from 'react-redux';
import { login } from '../../../Redux/personalDetail';
import FormHeader from './FormHeader';
import { toast } from 'react-toastify';

const LoginTab = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [togglePassword, setTogglePassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateEmail = (email) => {
        // Simple regex for email validation
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (emailError) {
            if (e.target.value) {
                setEmailError('');
                if (!validateEmail(e.target.value)) {
                    setEmailError('Please enter a valid email address');
                }
            } else {
                setEmailError('Email is required');
            }
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (passwordError) {
            if (e.target.value) {
                setPasswordError('');
            } else {
                setPasswordError('Password is required');
            }
        }
    };

    const loginAuth = async (e) => {
        e.preventDefault();

        // Reset errors
        setEmailError('');
        setPasswordError('');

        let hasError = false;

        // Validate email
        if (!email) {
            setEmailError('Email is required');
            hasError = true;
        } else if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            hasError = true;
        }

        // Validate password
        if (!password) {
            setPasswordError('Password is required');
            hasError = true;
        }

        // If there is an error, do not proceed
        if (hasError) {
            return;
        }

        const data = { password: password, email: email };
        const loginResponse = await postDataWithoutResData(adminLogin, data);
        if (loginResponse.data.status === true) {
            authServices.setUser(loginResponse.data.data.token, loginResponse.data.data.role);
            toast.success('Login Success...!');
            navigate(`${process.env.PUBLIC_URL}/dashboard/ecommerce`);
            dispatch(login({
                username: loginResponse.data.data.user_name,
                role: loginResponse.data.data.role
            }));
        } else if(loginResponse.data.status === false){
            toast.error(loginResponse.data.message);
        } else {
            toast.error(loginResponse.message);
        }
    };

    return (
        <Fragment>
            <Form className="theme-form" onSubmit={loginAuth}>
                <FormHeader />
                <FormGroup className='form-group'>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                        className="form-control"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    {emailError && <div className="text-danger">{emailError}</div>}
                </FormGroup>
                <FormGroup className='form-group'>
                    <Label className="col-form-label">{Password}</Label>
                    <div className='form-input position-relative'>
                        <Input
                            className="form-control"
                            type={togglePassword ? 'text' : 'password'}
                            value={password}
                            onChange={handlePasswordChange}
                            // required
                        />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                        {passwordError && <div className="text-danger">{passwordError}</div>}
                    </div>
                </FormGroup>
                <FormGroup className="form-group mb-0">
                    <div className="checkbox p-0">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                    </div>
                    <Link className="link" to={`${process.env.PUBLIC_URL}/pages/auth/forget-pwd`}>{ForgotPassword}</Link>
                </FormGroup>
                <div className='text-end mt-3'>
                    <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', type: 'submit' }}>{SignIn}</Btn>
                </div>
                {/* <SocialAuth /> */}
            </Form>
        </Fragment>
    );
};

export default LoginTab;
