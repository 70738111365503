import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIndustryData, getAddons, editAddons } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { DiscriptionText, Name, NameError, SubmitForm, Price, MRP, Industry, PriceError, MrpError, IndustryError, AddAddons } from '../../../Constant';
import Typeahead from "../../../CommonElements/TypeAhead";
import SpinnerLoader from "../../../Layout/SpinnerLoader";

const AddonsEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const param1 = searchParams && searchParams.substring(1);
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {}
    });
    const [industry, setIndustry] = useState([]);
    const [industryOption, setIndustryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        industryData();
        getAddonsData();
    }, []);

    useEffect(() => {
        setIndustryOption(industry.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [industry]);

    const industryData = async () => {
        const data = await getDataWithToken(getIndustryData);
        setIndustry(data.data);
    };

    const getAddonsData = async () => {
        setLoading(true);
        let data = await getDataWithToken(getAddons + `?add_on_id=${param1}`)
        setValue('name', data.data[0].name);
        setValue('price', data.data[0].price);
        setValue('mrp', data.data[0].mrp);
        setValue('message', data.data[0].description);
        setValue('industry', { label: data.data[0].industry_name, value: data.data[0].industry_type_id });
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = { add_on_id: param1, name: data.name, price: data.price, mrp: data.mrp, industry_type_id: data.industry.value, description: data.message }
        const editPackageResponse = await postDataWithToken(editAddons, postRequest);
        if (editPackageResponse.message === 'success') {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/master-records/addons`);
        } else {
            setLoading(false);
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}

            <Breadcrumbs mainTitle='Edit Add-on' parent="Administration" title="Addons" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddAddons}</H3>
                    {/* <span>{CustomstyleText} <code className="text-danger">{novalidate}</code> {CustomstyleText2} <code className="text-danger">&lt;{form}&gt;</code>{CustomstyleText3}</span><span>{CustomstyleText4} <code className="text-danger">{invalid} </code> {and} <code className="text-danger">{valid} </code> {CustomstyleText5}</span> */}
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{Industry}</Label>
                                <Controller
                                    name="industry"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={industryOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Price}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('price', { required: true })} />
                                <span className="text-danger">{errors.price && PriceError}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{MRP}</Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('mrp', { required: true })} />
                                <span className="text-danger">{errors.mrp && MrpError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{DiscriptionText}</Label>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            className="form-control"
                                            {...field}
                                            rows="3" // Set the 'rows' attribute to 3 here
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AddonsEdit;