import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../Constant";
import CompanyForm from "./CompanyForm";
// import CompanyForm from "../CompanyForm";

const CompanyAdd = () => {
    return (
        <Fragment>
            <CompanyForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default CompanyAdd;