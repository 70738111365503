import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { editPaymentMethod, updatePaymentMethod, getAccountSelect } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import {  Name, SubmitForm, EditPaymentMethod, Type, Account } from '../../../Constant';
import Typeahead from "../../../CommonElements/TypeAhead";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const PaymentEdit = () => {
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: {}
    });

    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState([]);
    const [accountOption, setAccountOption] = useState([]);
    const navigate = useNavigate();

    const typeOptions = [
        { value: "Online", label: "Online" },
        { value: "Offline", label: "Offline" },
    ];

    useEffect(() => {
        accountData();
        getPaymentData()

    }, []);

    useEffect(() => {
        setAccountOption(account.map((item) => ({
            value: item.id,
            label: item.name,
        })));
    }, [account]);

    const accountData = async () => {
        const data = await getDataWithToken(getAccountSelect);
        setAccount(data.data);
    };

    const getPaymentData = async () => {
        setLoading(true);
        let data = await getDataWithToken(editPaymentMethod + `?payment_method_id=${param1}`);
        if (data && data.status === true) {
            const paymentMethod = data.data.payment_method;
            const accountId = paymentMethod.account_id;
            const account = data.data.accounts.find((item) => item.id === accountId);
            setValue('name', paymentMethod.name);
            setValue('type', { label: paymentMethod.type, value: paymentMethod.type });
            if (account) {
                setValue('account', { label: account.name, value: account.id });
            }
        }
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            payment_method_id: param1,
            name: data.name,
            type: data.type.value,
            account_id: data.account.value,
            descritpion: data.description,

        }
        const editPackageResponse = await postDataWithToken(updatePaymentMethod, postRequest);
        if (editPackageResponse.message === 'success') {
            toast.success('Success...!')
            navigate(`${process.env.PUBLIC_URL}/configuration/payment-method`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Edit Payment Method' parent="Configuration" title="Payment Method" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditPaymentMethod}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={typeOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.type?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Account} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="account"
                                    control={control} // Set the initial value to null
                                    render={({ field }) => (
                                        <Typeahead
                                            options={accountOption}
                                            onChange={(value) => {
                                                field.onChange(value); // Update the value in react-hook-form
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.account?.message}</p>

                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default PaymentEdit;