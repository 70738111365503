export function setUser(token, userType, customerToken) {
  localStorage.setItem(
    "superAdmin",
    JSON.stringify({
      userToken: token,
      userType: userType,
      customerToken: customerToken
    })
  );
}
export function getUser() {
  return JSON.parse(localStorage.getItem("superAdmin"));
}
export function signOutUser() {
  localStorage.clear();
  console.log('sign out');
}
export function isLoggedIn() {
  console.log('islogd ');
  const user = getUser();
  if (user !== null) {
    return true;
  } else {
    return false;
  }
}