import React, { Fragment } from "react";
import { Card, CardBody, Col, Table } from "reactstrap";
import { H3, H5, Image, P } from "../../../AbstractElements";
import { ExpiresToday, PaymentDues } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import { TopProductsData } from "../../../Data/Dashboard/Ecommerce/Data";
import { Link } from 'react-router-dom';

const WeekendView = () => {
    return (
        <Fragment>
            <Col xl="3" md="5 box-col-30">
                <Card className="top-products">
                    <HeaderCard title={PaymentDues} setting={true} />
                    <CardBody>
                        <div className="table-responsive sroller-style" style={{ maxHeight: '430px' }}>
                            <Table className="table table-bordernone" style={{ width: '100%', minWidth: '400px' }}>
                                <tbody>
                                    {TopProductsData.map((data) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <div className="icon">
                                                                <Image attrImage={{ className: "img-fluid", src: `${data.img}`, alt: "chair" }} />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/product-page/1`}>
                                                                <H5>{data.title}</H5>
                                                            </Link>
                                                            <P>{data.para}</P>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <H5>{data.coupon}</H5>
                                                    <P>{data.coupon_para}</P>
                                                </td>
                                                <td className="text-center"> <i className={`flag-icon ${data.icon}`}></i></td>
                                                <td>
                                                    <H5>{data.percent}</H5>
                                                    <P>{data.price}</P>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>

            </Col>

        </Fragment>


    );
};

export default WeekendView;
