import { H4 } from "../../AbstractElements";

export const paymentColumns = [
  {
    name: <H4>Sl</H4>,
    selector: (row) => row.Si,
    sortable: true,
    center: true,
    width:'50px'
  },
  {
    name: <H4> Name</H4>,
    selector: (row) => row.Name,
    sortable: true,
    center: true,
    wrap: true
  },
//   {
//     name: <H4>Code</H4>,
//     selector: (row) => row.Code,
//     sortable: true,
//     center: true,
//   },
  {
    name: <H4>Type</H4>,
    selector: (row) => row.Type,
    sortable: true,
    center: true,
  },
  {
    name: <H4>Account</H4>,
    selector: (row) => row.account_name,
    sortable: true,
    center: true,
  },

  {
    name: <H4>Status</H4>,
    selector: (row) => row.Status,
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H4>Action</H4>,
    selector: (row) => row.action,
    sortable: true,
    center: true,
  },
];