import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import styled from "styled-components";
import { addRole, getPermission, getRole, postAddAccount } from "../../../CommenURL";
import { getDataWithToken, postDataWithToken } from "../../../Utils/restUtils";
import { Name, NameError, SubmitForm, Packages, AddRole, Checked, Permission, Addrole, Role, EditRole } from '../../../Constant';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { PermissionColumns } from "../../../Data/MockTable/TableHeaderList";
import Transparentspinner from "../../Common/loader/Transparentspinner";

const RoleEdit = () => {
console.log("hiiii");
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false); // Set loading to true initially
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const navigate = useNavigate();
    // const authState = useSelector((state) => state.auth);
    // const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const customStyles = {
        headRow: {
            style: {
                display: "none",
            },
        },
        cells: {
            style: {
                borderLeft: '1px solid #ccc'
            },
        },
    };

    const TableContainer = styled.div`
  border: 1px solid #ccc;
  padding: 0px;`;

    // useEffect(() => {
    //     const fetchData = async () => {
    //         await roleData(); // Wait for roleData to complete before rendering
    //         setLoading(false); // Once roleData is complete, set loading to false
    //     };
    //     fetchData();
    // }, []);

    // useEffect(() => {
    //     if (!loading) {
    //         const initialSelectedCheckboxes = accountList.reduce((acc, row) => {
    //             row.checkboxes.forEach((checkbox) => {
    //                 acc[`${row.id}-${checkbox.id}`] = checkbox.Checked;
    //             });
    //             return acc;
    //         }, {});
    //         setSelectedCheckboxes(initialSelectedCheckboxes);
    //     }
    // }, [loading]);

    // useEffect(() => {
    //     if (!loading) {
    //         roleData(); // Call roleData again only if loading is false
    //     }
    // }, [selectedCheckboxes]);

    // const roleData = async () => {
        // let apiResponse = await getClientUrlDataToken(authState.apiUrl, getPermission);
        // if (apiResponse.status === true) {
        //     let selectedId = await getClientUrlDataToken(authState.apiUrl, editRole + `?role_id=${param1}`);
        //     if (selectedId.status === true) {

        //         setValue("name", selectedId.data.role.name);
        //         setResetPaginationToggle(!resetPaginationToggle);

        //         const tableData = Object.keys(apiResponse.data).map((key) => {
        //             const module = { id: key, name: key, checkboxes: [] };
        //             apiResponse.data[key].map((item) => {
        //                 const isChecked = selectedId && selectedId.data.permissions && selectedId.data.permissions.includes(item && item.name);
        //                 module.checkboxes.push({
        //                     displayName: item.display_name,
        //                     id: item.id,
        //                     name: item.name,
        //                     Checked: isChecked,
        //                 });
        //             });
        //             return module;

        //         });
        //         setAccountList(tableData);
        //     } else if (apiResponse.status >= 400 && apiResponse.status <= 405) {
        //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        //     } else if (apiResponse.status >= 500 && apiResponse.status <= 505) {
        //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        //     }
        //     else {
        //         navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        //     }

        // } else if (apiResponse.status >= 400 && apiResponse.status <= 405) {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        // } else if (apiResponse.status >= 500 && apiResponse.status <= 505) {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        // }
        // else {
        //     navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        // }
    // };

    const handleCheckboxChange = (id, index) => {
        setSelectedCheckboxes((prevCheckboxes) => {
            const key = `${id}-${index}`;
            const updatedCheckboxes = { ...prevCheckboxes, [key]: !prevCheckboxes[key] };
            return updatedCheckboxes;
        });
    };

    const onSubmit = async (data) => {
        const selectedCheckboxIds = Object.keys(selectedCheckboxes || {})
            .filter((key) => selectedCheckboxes[key])
            .map((key) => key.split('-')[1]);
        const integerValues = selectedCheckboxIds.map(value => parseInt(value, 10))

        const postRequest = {role_id:param1, role_name: data.name, permissions: integerValues }
        setLoading(true);
        const addTaxResponse = await postClientUrlWithToken(authState.apiUrl, updateRole, postRequest);
        if (addTaxResponse.status === 200) {
            if (addTaxResponse.data.status === true) {
                navigate(`${process.env.PUBLIC_URL}/menu/role`);
            } else {
                toast.error(addTaxResponse.data.message);
            }
        } else if (addTaxResponse.status >= 400 && addTaxResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addTaxResponse.status >= 500 && addTaxResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }

        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle={Role} parent="Administration" title={EditRole} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditRole}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6' className="ms-md-4">
                                <Label className="form-label" for="validationCustom01">{Name}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                        </Row>
                        <CardBody>
                            <div className="order-history table-responsive tableContainer">
                                <Label className="form-label" for="validationCustom01">Permission</Label>
                                <TableContainer>
                                    <DataTable
                                        data={accountList}
                                        columns={[
                                            {
                                                name: 'Module',
                                                selector: (row) => row.name,
                                                width: '18%',

                                            },
                                            {
                                                name: 'Checkboxes',
                                                width: '82%',
                                                cell: (row) => {
                                                    return (
                                                        <div>
                                                            {row.checkboxes.map((checkbox, index) => {
                                                                // const isChecked =  selectedCheckboxes[`${row.id}-${checkbox.id}`] ||  checkbox.Checked === true ? true : false;                                                               
                                                                const isChecked = selectedCheckboxes[`${row.id}-${checkbox.id}`];

                                                                return (
                                                                    <span style={{ flex: "none" }} key={index} className="form-check form-check-inline checkbox checkbox-dark mb-0">
                                                                        <Input
                                                                            id={`checkbox - ${row.id}-${index}`}
                                                                            type="checkbox"
                                                                            checked={isChecked}
                                                                            onChange={() => handleCheckboxChange(row.id, checkbox.id)}
                                                                        />
                                                                        <Label for={`checkbox - ${row.id}-${index}`}>{checkbox.displayName}</Label>
                                                                    </span>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                }
                                            }
                                        ]}
                                        customStyles={customStyles}
                                        dense
                                        style={{ border: '1px solid' }}
                                    />

                                </TableContainer>
                            </div>
                        </CardBody>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default RoleEdit;