export const industryColumns = [
  {
    name: 'Icons',
    selector: (row) => row.icons,
    sortable: true,
    center: true,
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    wrap: true
  },
  {
    name: 'URL',
    selector: (row) => row.url,
    sortable: true,
  },
  {
    name: 'Comment',
    selector: (row) => row.comment,
    sortable: true,
    wrap: true
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
    center: true,
  },
  {
    name: 'Action',
    selector: (row) => row.action,
    sortable: true,
  },
];