import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, InputGroup, Input } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addCoupon, getStatus } from "../../../CommenURL";
import { postDataWithToken } from "../../../Utils/restUtils";
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import DatePicker from 'react-datepicker';
import Typeahead from "../../../CommonElements/TypeAhead";
import { CouponName, Code, Type, Discount, StartDate, EndDate, UsersPerCoupon, UsersPerCustomer, SubmitForm, AddCoupon, Status } from '../../../Constant';
import '../../../Style/AdminStyle.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from 'moment';
import Transparentspinner from "../../Common/loader/Transparentspinner";
import { toast } from "react-toastify";

const schema = yup.object().shape({
    name: yup.string().required().label('Coupon Name'),
    code: yup.string().required().label('Code'),
    type: yup.mixed().required().label('Type'),
    discount: yup.string().required().label('Discount'),
});

const CouponForm = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const navigate = useNavigate();

    const typeOptions = [
        { value: 'cash', label: 'Cash' },
        { value: 'percentage', label: 'Percentage' },
    ];

    const handleChangeDate = date => {
        setStartDate(date);
    };

    const handleChangeEndDate = date => {
        setEndDate(date);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            name: data.name,
            code: data.code,
            type: data.type.value,
            discount: data.discount,
            start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'), 
            end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            max_uses: data.max_uses,
            use_per_customer: data.use_per_customer,
          
        }
        const addPackageResponse = await postDataWithToken(addCoupon, postRequest);
        if (addPackageResponse.status === true) {
            toast.success(addPackageResponse.message);
            navigate(`${process.env.PUBLIC_URL}/configuration/coupon`);

        } else {
            toast.error(addPackageResponse.message);
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}

            <Breadcrumbs mainTitle='Coupons' parent="Configuration" title="Coupons" />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AddCoupon}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{CouponName} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="code">{Code} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="code" type="text"  {...register('code', { required: true })} />
                                <p className="text-danger">{errors.code?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" >{Type} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={typeOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                 <p className="text-danger">{errors.type?.message}</p>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" for="discount">{Discount} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="discount" type="number" min="0" {...register('discount', { required: true })} />
                                <p className="text-danger">{errors.discount?.message}</p>
                            </Col>

                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="date">{StartDate} <span className="requireStar">*</span></Label>
                                    <Col xl='12' md="12" sm='12'>
                                        <DatePicker
                                            id="date"
                                            style={{ padding: '12px 100px' }}
                                            className="form-control "
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleChangeDate}
                                            // peekNextMonth
                                            selected={startDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            required
                                        />
                                    </Col>
                                    
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label className="form-label" for="enddate">{EndDate} <span className="requireStar">*</span></Label>
                                    <Col xl='12' md="12" sm='12'>
                                        <DatePicker
                                            id="enddate"
                                            style={{ padding: '12px 100px' }}
                                            className="form-control "
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            onChange={handleChangeEndDate}
                                            // peekNextMonth
                                            selected={endDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            required
                                        />
                                    </Col>
                                 
                                </FormGroup>
                            </Col>
                        
                            <Col md='6'>
                                <Label className="form-label" for="max_uses">{UsersPerCoupon} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="max_uses" type="number" min="0" {...register('max_uses', { required: true })} />
                                <span className="text-danger">{errors.max_uses && 'Max uses is required'}</span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="use_per_customer">{UsersPerCustomer} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="use_per_customer" type="number" min="0" {...register('use_per_customer', { required: true })} />
                                <span className="text-danger">{errors.use_per_customer && 'Use per customer is required'}</span>
                            </Col>


                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{SubmitForm}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default CouponForm;
