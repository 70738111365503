import React, { Fragment } from "react";
import { Footerdark, PageLayout } from "../../../../Constant";
import PaymentForm from "./PaymentForm";

const PaymentAdd = () => {
    return (
        <Fragment>
            <PaymentForm mainTitle={Footerdark} title={Footerdark} parent={PageLayout} />         
        </Fragment>
    )
}
export default PaymentAdd;